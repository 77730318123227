import Icono from "../../img/icon_fit.png";
import { Card, Button, Row, Col, Avatar, Tag, Space } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getSesion, cleanAcentos } from "../../utils/utils";

const { Meta } = Card;

const Home = () => {
  const [niveles, setniveles] = useState([]);
  const [nivelesFiltrados, setNivelesFiltrados] = useState([]);
  const [programas, setProgramas] = useState([]);
  const [programaActivo, setProgramaActivo] = useState("Todos");
  const [loadCard, setloadCard] = useState(true);

  useEffect(() => {
    const { niveles } = getSesion();
    setniveles(niveles);
    setNivelesFiltrados(niveles);
    const programasUnicos = Array.from(
      new Set(niveles.map((n) => n.nombrePrograma))
    );
    setProgramas(programasUnicos);
    setloadCard(false);
  }, []);

  const handleFiltro = (programa) => {
    setProgramaActivo(programa);
    if (programa === "Todos") {
      setNivelesFiltrados(niveles);
    } else {
      const filtrados = niveles.filter((n) => n.nombrePrograma === programa);
      setNivelesFiltrados(filtrados);
    }
  };

  return (
    <>
      <Space
        style={{ marginTop: 10, justifyContent: "center", width: "100%" }}
        wrap
      >
        <Tag
          color={programaActivo === "Todos" ? "green" : "default"}
          onClick={() => handleFiltro("Todos")}
          style={{ cursor: "pointer", borderRadius: "20px" }}
        >
          Todos
        </Tag>
        {programas.map((programa) => (
          <Tag
            key={programa}
            color={programaActivo === programa ? "green" : "default"}
            onClick={() => handleFiltro(programa)}
            style={{ cursor: "pointer", borderRadius: "20px" }}
          >
            {programa}
          </Tag>
        ))}
      </Space>

      <Row
        style={{ alignItems: "center", justifyContent: "center" }}
        gutter={[16, 16]}
      >
        {nivelesFiltrados.map((nivel) => (
          <Col
            sm={24}
            md={11}
            lg={8}
            xl={7}
            xxl={5}
            className={"colNivel"}
            key={nivel.idNivel}
          >
            <Card
              className={"cardNivel"}
              hoverable
              bordered
              loading={loadCard}
              cover={
                <img
                  className={"imgCard"}
                  alt="example"
                  src={`${window.baseUrlFiles}/${nivel.recurso}`}
                />
              }
              actions={[
                <Button
                  type="primary"
                  ghost
                  style={{
                    width: "90%",
                    backgroundColor: "#389e0d",
                    borderColor: "green",
                    color: "green",
                  }}
                >
                  <Link
                    to={`/clases/${cleanAcentos(nivel.nombrePrograma)}/${
                      nivel.idNivel
                    }`}
                  >
                    ¡Empecemos!
                  </Link>
                </Button>,
              ]}
            >
              <Meta
                avatar={
                  <Avatar src={Icono} style={{ width: 25, height: 25 }} />
                }
                title={nivel.nombrePrograma}
                description={nivel.nombre}
              />
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Home;

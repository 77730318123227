import React, { useEffect } from "react"
import { Modal, Form, Input, Row, Col } from "antd"
import { sendServer, toast } from "../../utils/utils"

const CategoriaUpd = ({ visible, setVisible, loadData, dataUpd }) => {

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({...dataUpd});
    console.log('HIJO UPD');
    console.log(dataUpd);
	}, [visible])

  const handleSubmit = async (item) => {
    try {
      const { key } = item;
      item.id = key;
      const { msg } = await sendServer(item, "PUT", "categoria/actualizar");
      loadData();
      toast("success", "fitconluapp", "Actualizado correctamente");
      closeModal();
    } catch (e) {
      const { status, msg } = e;
      if (msg === "algunCampoDuplicado") {
        toast(
          "error",
          "fitconluapp",
          "No se puede actualizar, categoria duplicada"
        );
      } else {
        toast('warning', 'fitconluapp', `No se puede agregar - ${msg}`);
      }
    }
  };

  const closeModal = () => {
    form.resetFields();
    setVisible(false);
  };

  return (
    <Modal
      title="Actualizar categoria"
      visible={visible}
      width={1000}
      onOk={form.submit}
      onCancel={() => {
        closeModal();
      }}
      destroyOnClose={true}
      okText={"Guardar"}
      cancelText={"Cancelar"}
    >
      <Form
        name="categoria_upd"
        onFinish={handleSubmit}
        form={form}
      >
        <Row gutter="24">
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label={"key"}
              labelCol={{ span: 24 }}
              name="key"
              rules={[
                { required: true, message: "Por favor ingresa el key!" },
              ]}
              hidden={true}
            >
              <Input size="large" placeholder="key categoria" />
            </Form.Item>
            <Form.Item
              label={"Nombre"}
              labelCol={{ span: 24 }}
              name="nombre"
              rules={[
                { required: true, message: "Por favor ingresa el nombre!" },
              ]}
            >
              <Input size="large" placeholder="Nombre categoria" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CategoriaUpd;

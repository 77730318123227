import { useState } from 'react'

export default function useToken() {

  const getToken = () => {
    const tokenString = sessionStorage.getItem('sesion');
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    const { niveles } = {...userToken};
    sessionStorage.setItem('sesion', JSON.stringify(userToken));
    sessionStorage.setItem('remoteNiveles', JSON.stringify(niveles));
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token
  }
}
import React, { useEffect } from "react"
import { Modal, Form, Input, Row, Col } from "antd"
import { sendServer, toast } from '../../utils/utils'

const CategoriaAdd = ({ visible, setVisible, loadData }) => {
  
  const [form] = Form.useForm();

  useEffect(() => {
    console.log('HIJO ADD');
  }, [visible])
  
  const handleSubmit = async (item) => {

    try {
      const { msg } = await sendServer(item, 'POST', 'categoria/agregar');
      loadData();
      toast('success', 'fitconluapp', 'Agregado correctamente');
      closeModal();
    } catch (e) {
      const { status, msg } = e;
      if(msg === 'algunCampoDuplicado') {
        toast('warning', 'fitconluapp', 'No se puede agregar, categoria duplicada');
      } else {
        toast('warning', 'fitconluapp', `No se puede agregar - ${msg}`);
      }
    }
  
  };

  const closeModal = () => {
    form.resetFields();
    setVisible(false);
  }

  return (
    <Modal
      title="Agregar categoria"
      visible={visible}
      width={1000}
      onOk={form.submit}
      onCancel={()=>{closeModal()}}
      destroyOnClose={true}
      okText={"Guardar"}
      cancelText={"Cancelar"}
    >
      <Form
        name="categoria_add"
        onFinish={handleSubmit}
        form={form}
      >
        <Row gutter="24">
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label={"Nombre"}
              labelCol={{span:24}}
              name="nombre"
              rules={[
                { required: true, message: "Por favor ingresa el nombre!" },
              ]}
            >
              <Input size="large" placeholder="Nombre categoria" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CategoriaAdd;

import logo from "../../img/logo_texto.png";
import "./styles/template.css";
import "antd/dist/antd.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Layout,
  Menu,
  Breadcrumb,
  Row,
  Col,
  Tooltip,
  Avatar,
  Drawer,
} from "antd";
import {
  getSesion,
  logout,
  confirm,
  getKeySessionStorage,
} from "../../utils/utils";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import {
  faHeartbeat,
  faClipboard,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { Header, Content, Footer } = Layout;

const Template = (props) => {
  const { component: Component, titulo, token } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [sesionGlobal, setSesionGlobal] = useState(getSesion());
  const [otroPatch, setotroPatch] = useState(false);
  const [otroPatchTitle, setotroPatchTitle] = useState("");
  const niveles = getKeySessionStorage("remoteNiveles");

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const showUserName = () => {
    const { nombres } = getSesion();
    return nombres;
  };

  const handleLogout = () => {
    confirm("Salir", "¿Desea salir de la aplicación?", logout);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Drawer
        title={
          <Link to="/home" onClick={onCollapse}>
            <img className="logo" alt="example" src={logo} />
          </Link>
        }
        placement="left"
        onClose={onCollapse}
        visible={collapsed}
      >
        <Menu theme="dark" mode="inline">
          <Menu.Item
            key="1"
            className="myfont"
            onClick={onCollapse}
            icon={<FontAwesomeIcon icon={faHeartbeat} />}
          >
            <Link to="/home" className="brand-link">
              Programas
            </Link>
          </Menu.Item>
          {sesionGlobal.rol === "admin" ? (
            <></>
          ) : (
            // <Menu.Item key="2" className="myfont" onClick={onCollapse} icon={<FontAwesomeIcon icon={faClipboard} />}><Link to="/historia-clinica" className="brand-link">Historia clínica</Link></Menu.Item>
            niveles.map((nivel) => {
              if (nivel.idNivel == 2) {
                return (
                  <Menu.Item
                    key="2"
                    className="myfont"
                    onClick={onCollapse}
                    icon={<FontAwesomeIcon icon={faClipboard} />}
                  >
                    <Link to="/historia-clinica" className="brand-link">
                      Historia clínica
                    </Link>
                  </Menu.Item>
                );
              }
            })
          )}
          {sesionGlobal.rol === "admin" ? (
            <Menu.Item
              key="3"
              className="myfont"
              onClick={onCollapse}
              icon={<FontAwesomeIcon icon={faUsers} />}
            >
              <Link to="/usuarios" className="brand-link">
                Usuarios
              </Link>
            </Menu.Item>
          ) : null}
        </Menu>
      </Drawer>

      <Layout className="site-layout">
        <Header
          className="site-layout-background myHeader"
          style={{ padding: 0, position: "fixed", zIndex: 1, width: "100%" }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger btnTrigger",
              onClick: onCollapse,
            }
          )}

          <div style={{ float: "right", marginRight: "15px" }}>
            <Link to="/home">
              <Tooltip title="Programas">
                <HomeOutlined
                  style={{
                    cursor: "pointer",
                    fontSize: 20,
                    marginRight: 10,
                    color: "rgba(0, 0, 0, 0.85)",
                  }}
                />
              </Tooltip>
            </Link>
            <Tooltip placement="topLeft" title="Salir">
              <LogoutOutlined
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  marginRight: 8,
                  marginLeft: 10,
                }}
                onClick={handleLogout}
              />
            </Tooltip>
            <Tooltip title={showUserName}>
              <Avatar
                style={{
                  backgroundColor: "#f56a00",
                  verticalAlign: "middle",
                  marginRight: 15,
                  marginLeft: 10,
                  marginBottom: 8,
                  fontSize: 18,
                }}
                size={25}
                gap={1}
              >
                {sesionGlobal.nombres.slice(0, 1)?.toUpperCase()}
              </Avatar>
            </Tooltip>
          </div>
        </Header>
        <Content style={{ margin: "0 16px" }} className="myContent">
          <Breadcrumb style={{ margin: "80px 0 0 0" }}>
            <Breadcrumb.Item>
              <HomeOutlined />
            </Breadcrumb.Item>
            {otroPatch ? (
              <Breadcrumb.Item>{otroPatchTitle}</Breadcrumb.Item>
            ) : undefined}
            <Breadcrumb.Item>{titulo}</Breadcrumb.Item>
          </Breadcrumb>
          <Row className="site-layout-background">
            <Col span={24}>
              <Component
                props={props}
                setotroPatch={setotroPatch}
                setotroPatchTitle={setotroPatchTitle}
              ></Component>
            </Col>
          </Row>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Copyright todos los derechos reservados.
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Template;

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const baseUrl = process.env.REACT_APP_BASE;

//globales
window.baseUrlFiles = `${baseUrl}/public`;
window.baseUrlVideos = `${baseUrl}/clases/getVideo/videos`;

//historia clínica
window.incontineciaUrinaria = [
  'Nunca',
  'Una vez por semana',
  'Dos o tres veces por semana',
  'Una vez al día',
  'Varias veces al día',
  'Continuamenta'
]

window.cantidadPerdidaOrina = [
  'No se me escapa nada',
  'Muy poca cantidad',
  'Una cantidad moderada',
  'Mucha cantidad',
]

window.escapesOrinaAfectanVida = [
  'Nada',
  'Un poco',
  'Considerablemente',
  'Mucho',
]

window.tipoParto = [
  'Vaginal',
  'Cesárea',
]

window.tipoValoracion = [
  'Perímetro de cintura',
  'Perímetro abdominal',
  'Flexibilidad',
  'Test de diafragma',
  'Competencia del abdomen',
  'Competencia del Suelo Pélvico',
  'Diastasis abdominal',
]

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Form, Row, Col, Input, Switch, Select, Divider, Button } from 'antd'
import { Typography } from '@material-ui/core'
import { sendServer, toast, getSesion } from '../../utils/utils'
const { TextArea } = Input
const { Option } = Select

const MisDatos = ({ paciente, setFlagComponente }) => {

    const [form] = Form.useForm();
    const [flagMetodo, setFlagMetodo] = useState('agregar');
    const [data, setData] = useState([]);
    const history = useHistory();
    const refSesion = useRef(getSesion());

    useEffect(() => {
        loadData();
        return () => { }
    }, [])

    const otrosDatos = (item) => {
        const { rol } = getSesion();
        if(rol === 'user') {
            item.idUsuario = refSesion.current.id;
        } else {
            item.idUsuario = paciente.id;
        }
    }

    const handleSubmit = async (item) => {
        try {
            otrosDatos(item);
            console.log(flagMetodo);
            const { msg } = await sendServer(item, 'POST', `master/historia/${flagMetodo}`);
            toast('success', 'fitconluapp', 'Guardado correctamente');
            loadData();
        } catch (e) {
            const { status, msg } = e;
            toast('info', 'fitconluapp', msg);
        }

    };

    const handleCancel = () => {
        const { rol } = getSesion();
        console.log(rol);
        if(rol === 'user') {
            history.push('/home');
        } else {
            setFlagComponente('Historias');
        }
        
    }

    const loadData = async () => {
        try {
            const { data } = await sendServer(null, 'GET', `master/historia/listarXUsuario/${paciente.id}`);
            const [historia] = data;
            sessionStorage.setItem('historiaSelecionada', JSON.stringify(historia));
            if (historia.id) {
                historia.hipertension = historia.hipertension?true:false;
                historia.diabetes = historia.diabetes?true:false;
                historia.herniaInguinal = historia.herniaInguinal?true:false;
                historia.herniaUmbilicalAbdominal = historia.herniaUmbilicalAbdominal?true:false;
                historia.cardiopatias = historia.cardiopatias?true:false;
                setData(historia);
                setFlagMetodo('actualizar');
                form.setFieldsValue({
                    ...historia
                });
            } else {
                setFlagMetodo('agregar');
                if(refSesion.current.rol === 'admin') {
                    form.setFieldsValue({
                        nombreCompleto: paciente.nombreCompleto
                    });
                } else {
                    form.setFieldsValue({
                        nombreCompleto: refSesion.current.nombres
                    });
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div style={{padding: 20}}>
            <Typography
                align="center"
                variant="h5"
                color="primary"
                style={{ margin: 10, fontWeight: 'bold' }}
            >MIS DATOS</Typography>
            <Form
                name="frm_miscatos"
                onFinish={handleSubmit}
                form={form}
            >
                <Row gutter="24">

                    {/* id historia */}
                    <Form.Item
                        name="id"
                        hidden={true}
                    >
                        <Input />
                    </Form.Item>

                    <Divider plain>Personal</Divider>

                    {/* Nombre completo */}
                    <Col xs={24} sm={24} md={6}>
                        <Form.Item
                            label={"Nombres y apellidos"}
                            labelCol={{ span: 24 }}
                            name="nombreCompleto"
                            rules={[
                                { required: true, message: "¡Valor obligatorio!" },
                            ]}
                        >
                            <Input type="text" />
                        </Form.Item>
                    </Col>

                    {/* Edad */}
                    <Col xs={24} sm={24} md={6}>
                        <Form.Item
                            label={"Edad"}
                            labelCol={{ span: 24 }}
                            name="edad"
                            rules={[
                                { required: true, message: "¡Valor obligatorio!" },
                            ]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </Col>

                    {/* Lugar de residencia */}
                    <Col xs={24} sm={24} md={6}>
                        <Form.Item
                            label={"Lugar de residencia"}
                            labelCol={{ span: 24 }}
                            name="lugarResidencia"
                            rules={[
                                { required: true, message: "¡Valor obligatorio!" },
                            ]}
                        >
                            <Input type="text" />
                        </Form.Item>
                    </Col>

                    {/* Ocupación/Profesión */}
                    <Col xs={24} sm={24} md={6}>
                        <Form.Item
                            label={"Ocupación o profesión"}
                            labelCol={{ span: 24 }}
                            name="profesion"
                            rules={[
                                { required: true, message: "¡Valor obligatorio!" },
                            ]}
                        >
                            <Input type="text" />
                        </Form.Item>
                    </Col>

                    <Divider plain>Enfermedades</Divider>
                    {/* hipertension */}
                    <Col xs={12} sm={3} md={4} xl={2}>
                        <Form.Item
                            label={"Hipertensión"}
                            labelCol={{ span: 24 }}
                            name="hipertension"
                            rules={[
                                { required: false, message: "¡Valor obligatorio!" },
                            ]}
                        >
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={data.hipertension}
                                onChange={()=>{setData({...data, hipertension:!data.hipertension})}}
                            />
                           
                        </Form.Item>
                    </Col>

                    {/* Diabetes */}
                    <Col xs={12} sm={3} md={4} xl={2}>
                        <Form.Item
                            label={"Diabetes"}
                            labelCol={{ span: 24 }}
                            name="diabetes"
                            rules={[
                                { required: false, message: "¡Valor obligatorio!" },
                            ]}
                        >
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={data.diabetes}
                                onChange={()=>{setData({...data, diabetes:!data.diabetes})}}
                            />
                        </Form.Item>
                    </Col>

                    {/* Hernia inguinal */}
                    <Col xs={12} sm={3} md={4} xl={2}>
                        <Form.Item
                            label={"Hernia inguinal"}
                            labelCol={{ span: 24 }}
                            name="herniaInguinal"
                            rules={[
                                { required: false, message: "¡Valor obligatorio!" },
                            ]}
                        >
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={data.herniaInguinal}
                                onChange={()=>{setData({...data, herniaInguinal:!data.herniaInguinal})}}
                            />
                        </Form.Item>
                    </Col>

                    {/* Hernia umbilical, abdominal */}
                    <Col xs={12} sm={3} md={4} xl={2}>
                        <Form.Item
                            label={"Hernia abdominal"}
                            labelCol={{ span: 24 }}
                            name="herniaUmbilicalAbdominal"
                            rules={[
                                { required: false, message: "¡Valor obligatorio!" },
                            ]}
                        >
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={data.herniaUmbilicalAbdominal}
                                onChange={()=>{setData({...data, herniaUmbilicalAbdominal:!data.herniaUmbilicalAbdominal})}}
                            />
                        </Form.Item>
                    </Col>

                    {/* cardiopatias */}
                    <Col xs={12} sm={3} md={4} xl={2}>
                        <Form.Item
                            label={"Cardiopatías"}
                            labelCol={{ span: 24 }}
                            name="cardiopatias"
                            rules={[
                                { required: false, message: "¡Valor obligatorio!" },
                            ]}
                        >
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={data.cardiopatias}
                                onChange={()=>{setData({...data, cardiopatias:!data.cardiopatias})}}
                            />
                        </Form.Item>
                    </Col>

                    {/* Hernia lumbar */}
                    <Col xs={24} sm={24} md={6}>
                        <Form.Item
                            label={"Hernia lumbar(nombre)"}
                            labelCol={{ span: 24 }}
                            name="cualHerniaLumbar"
                            rules={[
                                { required: false, message: "¡Valor obligatorio!" },
                            ]}
                        >
                            <Input type="text" />
                        </Form.Item>
                    </Col>

                    {/* Enfermedades respiratorias  */}
                    <Col xs={24} sm={24} md={6}>
                        <Form.Item
                            label={"Enfermedades respiratorias(nombres)"}
                            labelCol={{ span: 24 }}
                            name="cualEnfermedadesRespiratorias"
                            rules={[
                                { required: false, message: "¡Valor obligatorio!" },
                            ]}
                        >
                            <Input type="text" />
                        </Form.Item>
                    </Col>

                    <Divider plain>Antecedentes</Divider>

                    {/* Antecedentes Quirúrgicos  */}
                    <Col xs={24} sm={24} md={8}>
                        <Form.Item
                            label={"Cirugías que me han realizado y fecha"}
                            labelCol={{ span: 24 }}
                            name="antecedentesQuirurgicos"
                            rules={[
                                { required: false, message: "¡Valor obligatorio!" },
                            ]}
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>

                    {/* Antecedentes alérgicos/medicamento o alimento:  */}
                    <Col xs={24} sm={24} md={8}>
                        <Form.Item
                            label={"Alergia a medicamentosos o alimentos(nombres)"}
                            labelCol={{ span: 24 }}
                            name="antecedentesAlergicos"
                            rules={[
                                { required: false, message: "¡Valor obligatorio!" },
                            ]}
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>

                    {/* Antecedente farmacológicos/ que medicamentos consume  */}
                    <Col xs={24} sm={24} md={8}>
                        <Form.Item
                            label={"Medicamentos que consumo(nombres)"}
                            labelCol={{ span: 24 }}
                            name="antecedenteFarmacologicos"
                            rules={[
                                { required: false, message: "¡Valor obligatorio!" },
                            ]}
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>

                    <Divider plain>Test incontinencia orinaría</Divider>

                    {/* frecuenciaPerdidaOrina */}
                    <Col xs={24} sm={24} md={8}>
                        <Form.Item
                            name="frecuenciaPerdidaOrina"
                            label="Frecuencia perdida orina"
                            labelCol={{ span: 24 }}
                            hasFeedback
                            rules={[
                                { required: true, message: "¡Valor obligatorio!" },
                            ]}
                        >
                            <Select>
                                {
                                    window.incontineciaUrinaria.map((op) => {
                                        return (
                                            <Option key={op} value={op}>
                                                {op}
                                            </Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    {/* cantidadPerdidaOrina */}
                    <Col xs={24} sm={24} md={8}>
                        <Form.Item
                            name="cantidadPerdidaOrina"
                            label="Cantidad perdida orina"
                            labelCol={{ span: 24 }}
                            hasFeedback
                            rules={[
                                { required: true, message: "¡Valor obligatorio!" },
                            ]}
                        >
                            <Select>
                                {
                                    window.cantidadPerdidaOrina.map((op) => {
                                        return (
                                            <Option key={op} value={op}>
                                                {op}
                                            </Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    {/* escapesOrinaAfectanVida */}
                    <Col xs={24} sm={24} md={8}>
                        <Form.Item
                            name="escapesOrinaAfectanVida"
                            label="Cuánto afecta esto su vida cotidiana "
                            labelCol={{ span: 24 }}
                            hasFeedback
                            rules={[
                                { required: true, message: "¡Valor obligatorio!" },
                            ]}
                        >
                            <Select>
                                {
                                    window.escapesOrinaAfectanVida.map((op) => {
                                        return (
                                            <Option key={op} value={op}>
                                                {op}
                                            </Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24} style={{ padding: 15 }}></Col>

                    <div>
                        <Button type="primary" style={{ margin: 5 }} onClick={form.submit} >
                            Guardar
                        </Button>

                        <Button type="default" style={{ margin: 5 }} onClick={handleCancel}>
                            Devolver
                        </Button>
                    </div>
                </Row>
            </Form>
        </div>
    )
}

export default MisDatos

import React, { useEffect, useState } from "react"
import { Modal, Form, Input, Row, Col, Select, Switch } from "antd"
import { sendServer, toast } from "../../utils/utils"
import {
  CheckOutlined,
  CloseOutlined
} from "@ant-design/icons"
const { Option } = Select

const ProgramaAdmUpd = ({ visible, setVisible, loadData, dataUpd }) => {

  const [form] = Form.useForm();
  const [categorias, setCategorias] = useState();
  const [object, setObject] = useState({});

  const loadDataLocal = async () => {
    const { data } = await sendServer(null, 'GET', 'categoria/listar');
    setCategorias(data.map(d => <Option key={d.key}>{d.nombre}</Option>));
  }

  useEffect(() => {
    form.setFieldsValue({
      id: dataUpd.id,
      nombre: dataUpd.nombre,
      sku: dataUpd.sku,
      descripcion: dataUpd.descripcion,
      vigencia: dataUpd.vigencia
    });
    setObject({ ...dataUpd });
    loadDataLocal();
  }, [visible])

  const handleSubmit = async (item) => {
    try {
      item.estado = object.estado ? 1 : 0;
      item.id_categoria = object.id_categoria;
      console.log(item);
      const { msg } = await sendServer(item, "PUT", "programa/actualizar");
      loadData();
      toast("success", "fitconluapp", "Actualizado correctamente");
      closeModal();
    } catch (e) {
      const { status, msg } = e;
      if (msg === "algunCampoDuplicado") {
        toast(
          "error",
          "fitconluapp",
          "No se puede actualizar, programa duplicada"
        );
      } else {
        toast('warning', 'fitconluapp', `No se puede agregar - ${msg}`);
      }
    }
  };

  const closeModal = () => {
    form.resetFields();
    setVisible(false);
  };

  return (
    <Modal
      title="Actualizar programa"
      visible={visible}
      width={1000}
      onOk={form.submit}
      onCancel={() => {
        closeModal();
      }}
      destroyOnClose={true}
      okText={"Guardar"}
      cancelText={"Cancelar"}
    >
      <Form
        name="programa_upd"
        onFinish={handleSubmit}
        form={form}
      >
        <Row gutter="24">

          {/* ID */}
          <Form.Item
            label={"id"}
            labelCol={{ span: 24 }}
            name="id"
            rules={[
              { required: true, message: "Por favor ingresa el id!" },
            ]}
            hidden={true}
          >
            <Input size="large" placeholder="id categoria" />
          </Form.Item>

          {/* NOMBRE */}
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label={"Nombre"}
              labelCol={{ span: 24 }}
              name="nombre"
              rules={[
                { required: true, message: "Por favor ingresa el nombre!" },
              ]}
            >
              <Input size="large" placeholder="Nombre programa" />
            </Form.Item>
          </Col>

          {/* CATEGORIAS */}
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="id_categoria"
              label="Categoria"
              labelCol={{ span: 24 }}
              hasFeedback
              validateStatus="success"
              rules={[{ required: false, message: 'Por favor selecione la categoria!' }]}
            >
              <Select
                size="large"
                showSearch
                placeholder="Seleccione una categoria"
                labelInValue
                defaultValue={{ key: String(object.id_categoria) }}
                onChange={(e) => { object.id_categoria = e.value }}
                filterOption={(inputValue, option) => (
                  option.children.toLowerCase().includes(inputValue.toLowerCase())
                )}

              >
                {categorias}
              </Select>
            </Form.Item>
          </Col>

          {/* SKU */}
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label={"SKU"}
              labelCol={{ span: 24 }}
              name="sku"
              rules={[
                { required: true, message: "Por favor ingresa el sku!" },
              ]}
            >
              <Input size="large" placeholder="sku programa" />
            </Form.Item>
          </Col>

          {/* DESCRIPCION */}
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label={"Descripción"}
              labelCol={{ span: 24 }}
              name="descripcion"
              rules={[
                { required: false, message: "Por favor ingresa el descripción!" },
              ]}
            >
              <Input size="large" placeholder="Descripción programa" />
            </Form.Item>
          </Col>

          {/* VIGENCIA */}
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label={"Vigencia"}
              labelCol={{ span: 24 }}
              name="vigencia"
              rules={[
                { required: true, message: "Por favor ingresa el Vigencia!" },
              ]}
            >
              <Input type="number" size="large" placeholder="Vigencia del programa" />
            </Form.Item>
          </Col>


          {/* ESTADO */}
          <Col xs={24} sm={6} md={6}>
            <Form.Item
              label={"Estado"}
              labelCol={{ span: 24 }}
              name="estado"
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked={object.estado}
                onChange={(e) => { object.estado = e }}
              />
            </Form.Item>
          </Col>

        </Row>
      </Form>
    </Modal>
  );
};

export default ProgramaAdmUpd;

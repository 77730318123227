import React, { useState, useEffect } from 'react'
import { Button, Tooltip } from 'antd'
import MaterialTable, { MTableToolbar } from 'material-table'
import { Typography, Paper, makeStyles } from '@material-ui/core'
import { ReloadOutlined, PlusCircleOutlined } from "@ant-design/icons"
import moment from 'moment'
import { sendServer, getSesion, getKeySessionStorage } from '../../utils/utils'
import ValoracionAdd from './ValoracionAdd'

const useStyles = makeStyles(theme => ({
    table: {
     /*  '& tbody>.MuiTableRow-root:hover': {
        background: '#dedede',
      }, */
      '& tr:nth-child(even)': {
        background: '#dedede',
      },
    },
}));

const Valoracion = () => {

    const classes = useStyles();
    const [data, setData] = useState([]);
    const [state, setState] = useState(true);
    const [modalValoracion, setModalValoracion] = useState(false);
    const [dataUpd, setDataUpd] = useState({});
    const [historia, setHistoria] = useState({
        nombrePaciente: null
    });
    const [filtro, setFiltro] = useState({
        idHistoria: null
    });

    useEffect(() => {
        loadData();
        return () => { }
    }, [])

    const loadData = async () => {
        try {
            const valid = await otraInfo();
            if(valid) {
                setState(true);
                const { data } = await sendServer(null, 'GET', `master/valoracion/getByIdHistoriaClinica/${filtro.idHistoria}`);
                setData(data);
                setState(false);
            } else {
                setState(false);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const otraInfo = async () => {
        const { id, rol } = getSesion();
        if(rol === 'admin') {
            const filtroLocal = getKeySessionStorage('filtroHijos');
            if(filtroLocal) {
                filtro.idHistoria = filtroLocal.idHistoria;
                historia.nombrePaciente = filtroLocal.nombrePaciente;
                return true;
            } else {
                return false;
            }
        } else {
            const { data } = await sendServer(null, 'GET', `master/historia/listarXUsuario/${id}`);
            const [historia] = data;
            if(historia?.id) {
                filtro.idHistoria = historia.id;
                return true;
            } else {
                return false;
            }
        }
    }

    const upd = (row) => {
        setModalValoracion(true);
        setDataUpd({...row});
    }

    const columns = [
        {
            title: 'Valoración',
            field: 'valoracion'
        },
        {
            title: 'Fecha',
            field: 'fecha',
            render: fila => {
                const fecha = moment(fila.fecha).format('YYYY-MM-DD');
                if(fecha !== 'Invalid date') {
                   return fecha;
                }
            }
        },
        {
            title: 'Medida',
            field: 'medida',
        },
    ]

    return (
        <div style={{ marginTop: 20 }} className={classes.table}>
            <Typography
                align="center"
                variant="h5"
                color="primary"
                style={{ fontWeight: 'bold' }}
            >Valoración {historia.nombrePaciente}</Typography>
            <MaterialTable
                onRowClick={(item, row)=>{upd(row)}}
                title={''}
                style={{}}
                columns={columns}
                data={data}
                key='id'
                isLoading={state}
                localization={{
                    header: {
                        actions: 'Acción',
                    },
                }}
                options={{
                    actionsColumnIndex: -1,
                    headerStyle: {
                        fontWeight: 'bold',
                        backgroundColor: '#01579b',
                        color: '#FFF',
                    },
                }}
                actions={[
                    
                ]}
                components={{
                    Container: props => <Paper {...props} elevation={0} />,
                    Toolbar: props => (
                        <div className="myToolbar">
                            <MTableToolbar {...props} />
                            <div style={{ padding: '0px 10px' }}>
                                <Tooltip placement="top" title="Agregar">
                                    <Button size="middle" onClick={() => setModalValoracion(true)}
                                        style={{ marginRight: 5 }} type="primary" ghost>
                                        <PlusCircleOutlined />
                                    </Button>
                                </Tooltip>
                                <Tooltip placement="top" title="Sincronizar">
                                    <Button size="middle" onClick={() => loadData()}
                                        style={{ marginRight: 5 }} type="primary" ghost>
                                        <ReloadOutlined />
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    ),
                }}
            />

            <ValoracionAdd 
                visible={modalValoracion}
                setVisible={setModalValoracion}
                loadData={loadData}
                dataUpd={dataUpd}
                setDataUpd={setDataUpd}
            />
        </div>
    )
}

export default Valoracion

import "./App.css";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute';
import Login from './views/login/Login';
import { Categorias, Usuarios, Home, ProgramasAdm, Clases, HistoriaClinica } from './views/index';
import useToken from './components/hooks/useToken';

function App() {
  
  const {token, setToken} = useToken();

  return (
    <BrowserRouter>
      <Switch>

        {/* public routes */}
        <PublicRoute restricted={false} component={Login} path="/" exact/>

        {/* private routes */}
        <PrivateRoute component={Home} titulo={"Programas"} path="/home" token={token} exact/>
        <PrivateRoute component={Categorias} titulo={"Categorias"} path="/categorias" token={token} exact/>
        <PrivateRoute component={Usuarios} titulo={"Usuarios"} path="/usuarios" token={token} exact/>
        <PrivateRoute component={ProgramasAdm} titulo={"Programas"} path="/programasAdm" token={token} exact/>
        <PrivateRoute component={Clases} titulo={"Clases"} path="/clases/:programa/:idNivel" token={token} exact/>
        <PrivateRoute component={HistoriaClinica} titulo={"Historia clínica"} path="/historia-clinica" token={token} exact/>

        {/* pae not found */}
        <Route  render={props => (
          <Redirect to="/home" />
        )} />

      </Switch>
    </BrowserRouter>
  );
}

export default App;

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
    makeStyles,
    AppBar,
    Tabs,
    Typography,
    Box,
    Tab
} from '@material-ui/core'
import { People, AccountCircle, AccessibilityNew } from '@material-ui/icons'
import MisDatos from './MisDatos'
import Historias from './Historias'
import Hijos from './Hijos'
import Valoracion from './Valoracion'
import { getSesion } from '../../utils/utils'

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 30,
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

const HistoriaClinica = () => {

    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [flagComponente, setFlagComponente] = useState('MisDatos');
    const [paciente, setPaciente] = useState(getSesion());

    useEffect(() => {
        const { id, rol } = getSesion();
        setFlagComponente(rol === 'admin' ? 'Historias' : 'MisDatos');
        return () => {}
    }, [])
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs example"
                >
                    <Tab label="" icon={<AccountCircle />} {...a11yProps(0)} />
                    <Tab label="" icon={<People />} {...a11yProps(1)} />
                    <Tab label="" icon={<AccessibilityNew />} {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                {
                    flagComponente === 'MisDatos' ?
                        <MisDatos paciente={paciente} setFlagComponente={setFlagComponente}/>
                    : <Historias setPaciente={setPaciente} setFlagComponente={setFlagComponente}/>
                }
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Hijos />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Valoracion />
            </TabPanel>
        </div>
    );
}

export default HistoriaClinica;
import React, { useEffect } from "react"
import { Modal, Form, Input, Row, Col } from "antd"
import { sendServer, toast } from '../../utils/utils'

const CambioClave = ({ visible, setVisible, dataUpd }) => {

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ 
      ...dataUpd,
      clave: '' 
    });
    return () => {form.resetFields()}
  }, [visible])

  const handleSubmit = async (item) => {

    try {
      console.log(item);
      const { data } = await sendServer(item, 'POST', 'updPwdYcambioClave');
      toast('success', 'fitconluapp', 'Se cambio la clave correctamente');
      setVisible(false);
    } catch (e) {
      toast('info', 'fitconluapp', e);
    }

  };

  const closeModal = () => {
    form.resetFields();
    setVisible(false);
  }

  return (
    <Modal
      title="Cambiar contraseña"
      visible={visible}
      onOk={form.submit}
      onCancel={() => { closeModal() }}
      destroyOnClose={true}
      okText={"Guardar"}
      cancelText={"Cancelar"}
    >
      <Form
        autocomplete="off"
        onFinish={handleSubmit}
        form={form}
      >
        <Row gutter="24">
          <Form.Item
            name="userName"
            hidden={true}
            rules={[
              { required: true },
            ]}
          >
            <Input type="text" />
          </Form.Item>

          <Form.Item
            name="cambioClave"
            hidden={true}
            rules={[
              { required: true },
            ]}
          >
            <Input type="text" />
          </Form.Item>

          {/* nueva contraseña */}
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label={"Nueva contraseña"}
              labelCol={{ span: 24 }}
              name="clave"
              rules={[
                { required: true, message: "¡Valor obligatorio!" },
              ]}
            >
              <Input type="password" placeholder="Contraseña" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CambioClave;
